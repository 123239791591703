import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ConvertToArabicNumbers } from "../../components/DateFunction";
import DisplayText from "../../components/DisplayText/DisplayText";
import PageLayout from "../../components/PageLayout/PageLayout";
import { SelectBox } from "../../components/components/Inputs/index";
import { data_selector } from "../../store/DataReducer";
import {
  APPLICATION_USER,
  DELETE_PATIENT,
  EDIT_PATIENT,
  GET_PATIENT_BY_USER,
} from "./Api";
import { PatientsData } from "./Data";
import PatientForm from "./components/PatientForm";
import CrudTable from "../../components/CrudTable/CrudTable";
import PatientData from "./components/PatientData";

const PatientView = () => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const DefualtData = useRef({
    Id: 0,
    Type: 0,
  });
  const datax = () => {
    var obj = {};
    PatientsData(i18n, lookups).forEach(
      (e) => (obj[e.field] = e.data || e.dataType == "number" ? 0 : "")
    );
    return obj;
  };

  const defaultData = useRef(datax());
  const columnAttributesSummery = useMemo(
    () => [
      {
        column: "PatientInitials",
        summaryType: "count",
        customizeText: (data) => {
          return (
            t("Count") +
            " : " +
            (i18n.language == "en"
              ? data.value
              : ConvertToArabicNumbers(data.value))
          );
        },
      },
    ],
    [t, i18n]
  );
  const [values, setvalues] = useState(DefualtData.current);

  const columnAttributesRecipes = useMemo(
    () => PatientsData(i18n, lookups).slice(0, 5),
    [i18n.language, DisplayText, lookups, values.Type]
  );
  const columnAttributesRecipesAll = useMemo(
    () => PatientsData(i18n, lookups),
    [i18n.language, DisplayText, lookups, values.Type]
  );
  const handleChange = useCallback(({ name, value }) => {
    setvalues((prev) => ({ ...prev, [name]: value }));
  }, []);
  useEffect(() => {
    if (lookups.Roles && lookups.Roles.find((role) => role === "Supervisor")) {
      setLoading(true);
      APPLICATION_USER()
        .then(setUsers)
        .finally((e) => setLoading(false));
    }
  }, [lookups]);

  const isSupervisor = useCallback(
    () => lookups.Roles.find((role) => role === "Supervisor"),
    [lookups.Roles]
  );
  return (
    <PageLayout
      title={"Patients"}
      loading={loading}
      onHiding={() => setLoading(false)}
    >
      {isSupervisor() && (
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <SelectBox
              label={t("Type")}
              dataSource={users}
              keys={{ id: "Id", name: "UserName" }}
              value={values.Type}
              name="Type"
              requiredInput
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
      <div style={{ padding: "20px 0", direction: "ltr" }}>
        <CrudTable
          columnAttributes={columnAttributesRecipes}
          defaultValues={defaultData}
          DELETE={DELETE_PATIENT}
          EDIT={EDIT_PATIENT}
          GET={GET_PATIENT_BY_USER}
          ElementForm={PatientForm}
          view={isSupervisor()}
          excelCols={columnAttributesRecipesAll}
          loading={loading}
          setloading={setLoading}
          selectedId={values.Type}
          summaryItems={columnAttributesSummery}
          MasterRenderDetail={PatientData}
        />
      </div>
    </PageLayout>
  );
};

export default PatientView;

export const PatientsData = (i18n, lookups) => {
  return [
    // {
    //   caption: "Id",
    //   field: "Id",
    //   captionEn: "Id",
    //   allowEditing: false,
    //   widthRatio: "10px",
    //   dataType: "number",
    // },
    {
      caption: "National ID",
      field: "NationalID",
      captionEn: "National ID",
    },
    {
      caption: "Patient's initials",
      field: "PatientInitials",
      captionEn: "Patient's initials",
    },

    {
      caption: "Gender ",
      field: "Gender",
      captionEn: "Gender ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.Gender,
    },
    {
      caption: "Residency area  ",
      field: "ResidencyArea",
      captionEn: "Residency area ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ResidencyArea,
    },
    {
      caption: "educational level  ",
      field: "EducationLevel",
      captionEn: "educational level ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.EducationLevel,
    },
    {
      caption: "BMI (kg/m2) ",
      field: "BMI",
      captionEn: "BMI (kg/m2) ",
    },
    {
      caption: "Hypertension History",
      field: "HistoryOfHypertension",
      captionEn: "Hypertension History",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Heart rate",
      field: "HeartRate",
      captionEn: "Heart rate",
    },
    {
      caption: "SBP (mmHg)",
      field: "SBP",
      captionEn: "SBP (mmHg)",
    },
    {
      caption: "DBP (mmHg) ",
      field: "DBP",
      captionEn: "DBP (mmHg) ",
    },
    {
      caption: "Dyslipidemia",
      field: "TreatmentForDyslipidemia",
      captionEn: "Dyslipidemia",
      hint: "Treatment for Dyslipidemia",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Baseline Hemoglobin",
      field: "BaselineHemoglobin",
      captionEn: "Baseline Hemoglobin",
      hint: "Baseline Hemoglobin (gm%)",
    },
    {
      caption: "S. Creatinine mg/dl",
      field: "Creatinine",
      captionEn: "S. Creatinine mg/dl",
    },
    {
      caption: "Valvular disease",
      field: "ValvularHeartDisease",
      captionEn: "Valvular disease",
      hint: "Valvular heart disease",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Dilated cardiomyopathy",
      field: "DilatedCardiomyopathy",
      captionEn: "Dilated cardiomyopathy",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Congenital Disease",
      field: "CongenitalHeartDisease",
      captionEn: "Congenital Disease",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },

    {
      caption: "Smoking history",
      field: "SmokingHistory",
      captionEn: "Smoking history",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.SmokerHistory,
    },

    {
      caption: "DM history",
      field: "DMHistory",
      captionEn: "DM history",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Prior MI",
      field: "PriorMI",
      captionEn: "Prior MI",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Prior PCI ",
      field: "PriorPCI",
      captionEn: "Prior PCI ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Prior CABG ",
      field: "PriorCABG",
      captionEn: "Prior CABG  ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Prior peripheral",
      field: "PriorPeripheral",
      captionEn: "Prior peripheral",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Pre-cath Investigations ",
      field: "PreCath",
      captionEn: "Pre-cath Investigations ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Trans-z Echo EF% *",
      field: "TransThoracicEF",
      captionEn: "Trans-Thoracic Echo EF% *",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Trans-z Echo EF% Percentage",
      field: "TransThoracicEFPercentage",
      captionEn: "Trans-Thoracic Echo EF% Percentage",
    },
    {
      caption: "Trans-Thoracic Echo RWMA ",
      field: "TransThoracicRWMA",
      captionEn: "Trans-Thoracic Echo RWMA ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Trans-Thoracic Speckle Tracking Echo",
      field: "TransThoracicEcho",
      captionEn: "Trans-Thoracic Speckle Tracking Echo",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "EX-ECG",
      field: "EXECG",
      captionEn: "EX-ECG",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "EX-ECG state",
      field: "EXECGstate",
      captionEn: "EX-ECG",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.pos_neg,
    },
    {
      caption: "EX-ECHO",
      field: "EXECHO",
      captionEn: "EX-ECHO",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.pos_neg,
    },
    {
      caption: "EX-ECHO state",
      field: "EXECHOstate",
      captionEn: "EX-ECHO",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "C.T Coronary Angio",
      field: "CoronaryAngio",
      captionEn: "C.T Coronary Angio",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "MPI",
      field: "MPI",
      captionEn: "MPI",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "C-MRI",
      field: "CMRI",
      captionEn: "C-MRI",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Previous coronary angiography ",
      field: "PreviousCoronaryAngiography",
      captionEn: "Previous coronary angiography",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Coronary Approach",
      field: "CoronaryAngiography",
      captionEn: "Coronary Approach",
      hint: "Route of Approach for the current Coronary Angiography",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ApproachCoronaryAngiography,
    },
    {
      caption: "Coronary Angiography Result",
      field: "ResultCoronaryAngiography",
      captionEn: "Coronary Angiography Result",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ResultCoronaryAngiography,
    },
    {
      caption: "If abnormal",
      field: "IfAbnormal",
      captionEn: "If abnormal",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.IfAbnormal,
    },
    {
      caption: "If Lesion (LMA >50%)",
      field: "IfLesiona50",
      captionEn: "If Lesion (LMA >50%)*",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "If Lesion >70% ",
      field: "IfLesion70",
      captionEn: "If Lesion >70% ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.IfLesion70,
    },
    {
      caption: "Bifurcation Lesions",
      field: "BifurcationLesions",
      captionEn: "Bifurcation Lesions",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Total Occlusion ",
      field: "TotalOcclusion",
      captionEn: "Total Occlusion ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "LAD Lesion",
      field: "LADLesion",
      captionEn: "LAD Lesion",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Diagonal Lesion ",
      field: "DiagonalLesion",
      captionEn: "Diagonal Lesion ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "LCX Lesion  ",
      field: "LCXLesion",
      captionEn: "LCX Lesion ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "OM Lesion ",
      field: "OMLesion",
      captionEn: "OM Lesion ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "RCA Lesion  ",
      field: "RCALesion",
      captionEn: "RCA Lesion ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Diagnostic coronary",
      field: "DiagnosticCoronary",
      captionEn: "Diagnostic coronary",
      hint: "Diagnostic coronary Angiography Mortality",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Coronary Recommendation",
      field: "CoronaryAngiographyRecommendation",
      captionEn: "Coronary Recommendation",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.CoronaryAngiographyRecommendation,
    },
    {
      caption: "Postponing Coronary",
      field: "PostponingCoronaryIntervention",
      captionEn: "Postponing Coronary",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Number of stents",
      field: "NumberOfStents",
      captionEn: "Number of stents",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.NumberOfStents,
    },
    {
      caption: "Overlapping Stents",
      field: "OverlappingStents",
      captionEn: "Overlapping Stents",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Bifurcation Stents",
      field: "BifurcationStents",
      captionEn: "Bifurcation Stents",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Successful Intervention ",
      field: "SuccessfulIntervention",
      captionEn: "Successful Intervention ",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Complete Revascularization",
      field: "CompleteRevascularization",
      captionEn: "Complete Revascularization",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "second session",
      field: "SecondSession",
      captionEn: "second session",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "CABG*",
      field: "CABG",
      captionEn: "CABG*",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Angiography Mortality",
      field: "CoronaryAngiographyMortality",
      captionEn: "Angiography Mortality",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Infarction",
      field: "TypeOfInfarction",
      captionEn: "Infarction",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.InfarctionType,
    },
    {
      caption: "Infarction Site",
      field: "SiteOfInfarction",
      captionEn: "Infarction Site",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.ETF,
    },
    {
      caption: "Result",
      field: "EndResult",
      captionEn: "Result",
      display: i18n.language === "en" ? "NameEn" : "Name",
      value: "Id",
      data: lookups.Result,
    },
  ];
};

import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RadioBox from "../../../components/components/Inputs/RadioBox";
import {
  DateBox,
  NumberBox,
  SelectBox,
  TextBox,
} from "../../../components/components/Inputs/index";
import { Infarction } from "../../../services/SharedData";
import { data_selector } from "../../../store/DataReducer";
import { PatientsData } from "../Data";

const PatientForm = ({
  values,
  handleChange,
  handleGetImages,
  handleRemoveImage,
  setLoading,
}) => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);
  // const lookups = useSelector(data_selector);
  // const [cases, setCases] = useState([]);
  // useEffect(() => {
  //   setLoading(true);
  //   GET_CASES()
  //     .then(setCases)
  //     .finally(() => setLoading(false));
  // }, []);
  return (
    <>
      {PatientsData(i18n, lookups)
        // .slice(1, PatientsData(i18n, lookups).length - 1)
        .map((item, index) => (
          <div className="col-lg-6 col-md-6 col-sm-12">
            {item.data == null ? (
              <TextBox
                label={item.caption}
                value={values[item.field]}
                name={item.field}
                handleChange={handleChange}
                requiredInput
                required
                labelWidth="270px"
              />
            ) : (
              <>
                {item?.data?.length <= 3 ? (
                  <RadioBox
                    data={item.data}
                    value={values[item.field]}
                    name={item.field}
                    label={item.caption}
                    handleChange={handleChange}
                    labelWidth="270px"
                  />
                ) : (
                  <SelectBox
                    label={item.caption}
                    dataSource={item.data}
                    keys={{ id: "Id", name: "NameEn" }}
                    value={values[item.field]}
                    name={item.field}
                    requiredInput
                    handleChange={handleChange}
                    labelWidth="270px"
                  />
                )}
              </>
            )}
          </div>
        ))}
    </>
  );
};

export default memo(PatientForm);

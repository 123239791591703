import REQUEST from "../../services/Request";

export const GET_PATIENT_BY_USER = async (tye) => {
  return await REQUEST({
    method: "GET",
    url: "Patient/view/" + tye,
  });
};
export const APPLICATION_USER = async (tye) => {
  return await REQUEST({
    method: "GET",
    url: "Patient/users",
  });
};

export const EDIT_PATIENT = async (data) => {
  // let formData = new FormData();

  // for (let [key, value] of Object.entries(data)) {
  //   key !== "AccessoriesStores" &&
  //     formData.append(key.toString(), value !== null ? value : "");
  // }
  //formData.append("ItemsStores", JSON.stringify(data.ItemsStores));\
  console.log(data);
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Patient",
    data: data,
  });
};

export const DELETE_PATIENT = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Patient/" + id,
  });
};
export const GET_PATIENTS = async () => {
  return await REQUEST({
    method: "GET",
    url: "Patient",
  });
};
export const GET_PATIENT_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Patient/" + id,
  });
};

import React from "react";
import { Form, Item } from "devextreme-react/form";
import { PatientsData } from "../Data";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { data_selector } from "../../../store/DataReducer";
import LabelBox from "../../../components/components/Inputs/LabelBox";

const items = ["Address", "City", "Region", "PostalCode", "Country", "Phone"];

export const PatientData = (props) => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);
  function renderFormItem(item) {
    console.log(props.data.data[item.field]);
    console.log(
      item.data
        ? item.data.find((e) => e.Id == props.data.data[item.field])?.NameEn
        : item[item.field]
    );
    return item.data
      ? item.data.find((e) => e.Id == props.data.data[item.field])?.NameEn
      : props.data.data[item.field];
  }
  console.log();
  return (
    <div className="row">
      {PatientsData(i18n, lookups).map((item, index) => (
        <div className="col-6">
          <LabelBox
            label={i18n.language == "en" ? item.captionEn : item.caption}
            labelWidth="260px"
            value={renderFormItem(item)}
          />
        </div>
      ))}
    </div>
  );
};

export default PatientData;

import PatientView from "../../views/PatientView/PatientView";
import Users from "../../views/Users/Users";

export const routes = [
  {
    name: "Patients",
    route: "/patients",
    key: "Patients",
    Component: PatientView,
  },
  {
    name: "Patients",
    route: "/patients",
    key: "Supervisor",
    Component: PatientView,
  },
  {
    name: "Users",
    route: "/users",
    key: "Supervisor",
    Component: Users,
  },
];
